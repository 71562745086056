<template>
  <div v-if="!embed">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    callback: {
      type: Function,
      required: false,
      default: function(response) {
        console.log(response);
      }
    },
    publicKey: {
      type: String,
      required: false
    },
    embed: {
      type: Boolean,
      required: false
    },
    amount: {
      type: [String, Number],
      required: false
    },
    email: {
      type: String,
      required: false
    },
    narration: {
      type: String,
      required: false
    },
    paymentReference: {
      type: String,
      required: false
    }
  },
  mounted() {
    // this.payKorapay();
  },
  created() {
    this.scriptLoaded = new Promise(resolve => {
      this.loadScript(() => {
        resolve();
      });
    });
  },
  computed: {
    ...mapState("session", ["keys"]),
    reference() {
      let string = "";
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 32; i++) {
        string += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return string;
    },
    korapayKey() {
      return this.keys?.korapay_public_key;
    },
    payReference() {
      return `${this.paymentReference}-${new Date().getTime()}`;
    }
  },
  methods: {
    loadScript(callback) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://korablobstorage.blob.core.windows.net/modal-bucket/korapay-collections.min.js`;
      document.getElementsByTagName("head")[0].appendChild(script);
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        // Others
        script.onload = () => {
          callback();
        };
      }
    },
    payKorapay() {
      window.Korapay.initialize({
        key: this.korapayKey,
        amount: parseFloat(Math.ceil(this.amount)),
        currency: "NGN",
        customer: {
          name: `${this.user?.name} ${this.user?.last_name}`,
          email: this.email
        },
        reference: this.payReference,
        narration: this.narration,
        // reference: this.reference,
        onClose: () => {},
        onSuccess: data => {
          this.$emit("confirmTransfer", data);
        },
        onFailed: () => {
          // Handle when payment fails
        },
        onPending: () => {}
      });
    }
  }
};
</script>

<style></style>
